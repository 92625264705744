import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion"

export default function GameCard({ quiz, course, user, xp }) {
    return(
        <Card initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {/* Title */}
            <div>
                <h2>{ quiz.quiz_title }</h2>
            </div>
            <XP>
                <h1>{ quiz.xp }</h1>
                <p>XP</p>
            </XP>
            <div style={{ gridColumn: "1 / -1" }}>
                <p>{ quiz.quiz_description }</p>
                <Link to="/account/quiz" state={{ quiz: quiz, course: course, user: user, xp: xp }}>Play {quiz.quiz_title}</Link>
            </div>

        </Card>
    )
}

const Card = styled(motion.div)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10vh;
    padding: 40px;
    border-radius: 13px;
    box-shadow: 0 6px 8px rgba(0,0,0,.4);

    h1 {
        font-family: Gotham black;
        font-weight: 800;
        font-size: 48px;
        text-transform: uppercase;
        margin-top: 0;
    }
    
    h2 {
        font-family: Gotham black;
        font-weight: 800;
        font-size: 24;
        text-transform: uppercase; 
        margin-top: 0;
    }

    p {
        margin-bottom: 4vh;
        font-size: 22px;
        /* line-height: 1.3rem; */
    }

    a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        place-self: start start;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
            cursor: pointer;
        }
    }
`

const XP = styled.div`
    display: grid;
    place-self: start center;
    font-family: Gotham black;
    font-weight: 600;

    h1 {
        background: #6b6969;
        font-size: 54px;
        padding: 65px 50px;
        margin-bottom: 0;
        margin-top: 0;
    }

    p {
        font-size: 36px;
        color: white;
        background: #535151;
        margin-top: 0;
        text-align: center;
    }
`