import React, { useState, useEffect } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import styled from "styled-components";

export default function ScoreCard({ quiz, iterations }) {

    const [average, setAverage] = useState(null);


    // Calc the average score for this quiz
    const getAverage = useEffect(() => {
        let i = 0;
        let total = 0;
        for(i = 0; i < iterations.iterations.length; i++) {
            total += iterations.iterations[i].score;
        }
        total = total / iterations.iterations.length;
        setAverage(Math.round(total));
    }, [average]);


    return (
    <Card>
        <Details>
            <h2 style={{ gridColumn: `1 / -1` }}>{quiz.title}</h2>
            <h3>Average: {average != null ? `${average}%` : `...` } </h3>
            <p>Attempts: {iterations.iterations.length} </p>
        </Details>
        <ResponsiveContainer width="100%" height="100%">
        <LineChart data={iterations.iterations}>
            <Line type="monotone" dataKey="score" stroke="rgba(248, 109, 112, 1)" />
            <CartesianGrid stroke="#ccc" />
            <XAxis />
            <YAxis />
            <Tooltip />
        </LineChart>
        </ResponsiveContainer>
    </Card>
    )
}

export const Card = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 40px;
    padding: 40px;
    width: 100%;
    min-height: 500px;
    font-family: Gotham black;

    h2 {
        text-transform: uppercase;
        font-weight: 800;
    }


    @media(max-width: 800px) {
        grid-template-columns: 1fr;
    }
`


export const Details = styled.div`
    display: block;
`