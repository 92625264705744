import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Moment from 'react-moment';
import Navbar from "../components/auth/navbar"
import { PageWrapper, QuizDetail, AccountContainer } from "../styles/lib"
import Game from "../img/game.svg"


// Auth routes
import Dashboard from "../components/auth/dashboard/dashboard"
import Course from "../components/auth/course/course"
import CreateCourse from "../components/auth/teacher/createCourse"
import CourseStats from "../components/auth/course/courseStats"
import Quiz from "../components/auth/quiz"
import CreateQuiz from "../components/auth/teacher/createQuiz"
import EditQuiz from "../components/auth/teacher/editQuiz"
import CourseDashboard from "../components/auth/teacher/courseDashboard"
import StudentDetail from "../components/auth/teacher/studentDetail"



const Home = ({ user }) => {

  const MY_STATS = gql`
  query MyQuery($student: String) {
  completions(where: {student: {_eq: $student}}) {
    quiz_title
    date
    score
  }
}`

const { loading, error, data } = useQuery(MY_STATS, {variables: {
  student: user.name
}});

  return( 
  <PageWrapper style={{ placeItems: `center center` }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
    <AccountContainer>
    <h1>Hey, {user.name.split(' ')[0]}! You've completed {data && data.completions.length} quizzes so far.</h1>
    <Link to="/account/dashboard">Head to my course dashboard</Link>{" "}
      <img src={Game} alt="Person playing a game"/>
    </AccountContainer>   
  </PageWrapper>
  )
}


const Account = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  return (
    <>
      <Navbar user={user}>
        <Link to="/account">Home</Link>{" "}
        <Link to="/account/dashboard">Dashboard</Link>{" "}
        <a
          href="#logout"
          onClick={e => {
            logout()
            e.preventDefault()
          }}
        >
          Log Out
        </a>
      </Navbar>
      <Router>
        <Home path="/account" user={user} />
        <Dashboard path="/account/dashboard" user={user} />
        <Course path="/account/course" user={user}/>
        <CourseStats path="/account/courseStats" user={user}/>
        <CreateCourse path="/account/createCourse" user={user}/>
        <Quiz path="/account/quiz" user={user}/>
        <CreateQuiz path="/account/createQuiz" user={user}/>
        <EditQuiz path="/account/editQuiz" user={user}/>
        <CourseDashboard path="/account/course-dashboard" user={user}/>
        <StudentDetail path="/account/student-deets" user={user}/>
      </Router>
    </>
  )
}

export default Account
