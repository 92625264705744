import React from 'react'
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Challenges from "../course/challenges"
import styled from "styled-components"
import { PageWrapper, Breadcrumb, Current, CourseDashContainer, DashChallengeContainer } from "../../../styles/lib"


export default function CourseDashboard(props) {
    const { course } = props.location.state;
    const { user } = props;

    // Bring in course data
    const STATS_QUERY = gql`
    query MyQuery($course_id: String, $course_number: String) {
  enrollments(where: {course_id: {_eq: $course_number}}, order_by: {student_name: asc}) {
    student_name
    xp
  }
  quizzes(where: {parent_course: {_eq: $course_number}}) {
    quiz_title
    quiz_id
    quiz_description
    xp
    img_path
    questions {
      question
      correct_answer
    }
  }
    completions(where: {course_title: {_eq: $course_id}}) {
        date
        quiz_title
        score
        xp
    }
    challenges(where: {parent_course: {_eq: $course_number}}) {
        name
        goal
        eligible_quizzes
        start
        end
  }
    }`;
    
    const { loading, error, data } = useQuery(STATS_QUERY, {variables: {
        parent_course: course.id,
        course_number: course.id
      }
      });


    if(user.name !== "Rob Dominguez") {
        return (
            <div>
                <p>Unauthorized...</p>
            </div>
        )
    }


    
    return (
        <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Breadcrumb>
            <li><Link to="/account">My Account</Link></li>
            <li><Link to="/account/dashboard">My Dashboard</Link></li>
            <li><Link to="/account/course" state={{ course: course, user: user }}>{course.course_name}</Link></li>
            <li><Current>Dashboard: { course.course_name }</Current></li> 
            </Breadcrumb>
            <CourseDashContainer>
                <h2>Quizzes:</h2>
                <ul>
                    {data && data.quizzes.map(quiz => (
                        <li><Link to="/account/editQuiz" state={{ quiz: quiz, user: user }}>{quiz.quiz_title}</Link></li>
                    ))}
                </ul>
                <h2>Enrolled Students:</h2>
                <ol>
                {data && data.enrollments.map(student => (
                        <li><Link to="/account/student-deets" state={{ course: course, user: user, student: student }}>{student.student_name}: {student.xp}</Link></li>
                    ))}
                </ol>
                <h2>Challenges:</h2>
                {data && data.challenges.map(challenge => (
                            <div>
                                <h2>{challenge.name}</h2>
                                <DashChallengeContainer>
                                        <tr>
                                            <th>Student</th>
                                            <th>Progress</th>
                                            <th>Breakdown</th>
                                        </tr>
                                        {data.enrollments.map(student => (
                                            <Challenges  course={course}  user={{name: student.student_name}}  view={`table`}/>
                                        ))}
                                </DashChallengeContainer>
                            </div>
                    ))}
            </CourseDashContainer>
        </PageWrapper>
    )
}

