import React, { useState, useEffect, useMemo } from 'react'
import { Link } from "gatsby"
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import styled from "styled-components"
import { motion } from "framer-motion"
import GameCard from "./gameCard"
import Challenges from "./challenges"
import Stats from "./courseStats"
import { PageWrapper, Breadcrumb, Current, DetailsAction } from "../../../styles/lib"

export default function Course(props) {
    const { course } = props.location.state;
    const { user } = props;

    // state for xp
    const [xp, setXP] = useState(0);

const APOLLO_QUERY = gql`
query MyQuery($parent_course: String, $student: String) {
  quizzes(where: {parent_course: {_eq: $parent_course}}, order_by: {quiz_title: asc}) {
    quiz_title
    quiz_id
    quiz_description
    xp
    img_path
    questions {
      question
      correct_answer
    }
  }
  enrollments(where: {course_id: {_eq: $parent_course}, _and: {student_name: {_neq: "Rob Dominguez"}}}, order_by: {xp: desc}) {
    student_name
    xp
  }
}
`;


const { loading, error, data, refetch } = useQuery(APOLLO_QUERY, {variables: {
  parent_course: course.id,
  student: user.name
},
    options: {
      awaitRefetchQueries: true
 }
});


    return (
        <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Breadcrumb>
            <li><Link to="/account">My Account</Link></li>
            <li><Link to="/account/dashboard">My Dashboard</Link></li>
            <li><Current>{ course.course_name }</Current></li> 
            </Breadcrumb>
            <DetailsAction>
              {user && user.name == 'Rob Dominguez' ? <Link to="/account/course-dashboard" state={{ course: course, user: user }}>Check out the course dashboard</Link> : <Link to="/account/courseStats" state={{ course: course, user: user }}>Check my stats for this course</Link> }
            </DetailsAction>
            <div>
            {/* {loading && <p>Loading quizzes...</p>} */}
            {error && <p>Error: ${error.message}</p>}
            <div>
            </div>
            <Content>
            <div>
            <h2>Check out these games for {course.course_name}:</h2>
            {/* Games component */}
            {data && data.quizzes.map((quiz) =>
              <div>
              <GameCard quiz={quiz} course={course} user={user} />
              </div>
           )} 
            </div>
             {/* Leaderboard and challenges components */}
             <div>
           <h2>Leaderboard for { course.course_name }:</h2>
           <Leaderboard initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
             <tr>
               <th>#</th>
               <th>Name</th>
               <th>XP</th>
             </tr>
             {data && data.enrollments.map((enrollment, i) => 
              <tr style={enrollment.student_name == user.name ? {background: `black`, color: `white`} : null}>
                <td>{i + 1}</td>
                <td>{ enrollment.student_name }</td>
                <td>{ enrollment.xp }</td>
              </tr>
            )}
            </Leaderboard>
              <h2>Challenges for { course.course_name }:</h2>
              <Challenges  course={course}  user={user} />
             </div>
            </Content>
            {user && user.name == 'Rob Dominguez' ? <h5>IFF Teacher: <Link to="/account/createQuiz" state={{ course: course, user: user }}>Create new quiz for this class</Link></h5> : null}
           </div>
        </PageWrapper>
    )
}


// Styles
const CourseGallery = styled.div`
  display: grid;
  

  input {
    place-self: start start;
    padding: 5px 40px;
    margin-bottom: 20px;
    border-radius: 13px;
    font-family: Gotham Thin;
    font-weight: 800;
  }

  a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        place-self: start start;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
            cursor: pointer;
        }
    }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Leaderboard = styled(motion.table)`
  font-family: Gotham Black;
  min-width: 100%;
  border-collapse: collapse;
  margin-bottom: 80px;
  border-radius: 13px;
  box-shadow: 0 6px 8px rgba(0,0,0,.4);
  
  th {
    font-weight: 800;
    font-size: 48px;
    background: black;
    color: white;
    padding: 5px 10px;
  }

  td {
    text-align: center;
    font-size: 24px;
    padding: 5px 10px;
    border-bottom: solid 1px black;
  }

  tr:last-child {
    td {
      border-bottom: none;
    }
}
  `
