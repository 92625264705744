import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export default function CreateCourse(props) {
    const { user } = props;

// State for form
const [courseInfo, setCourseInfo] = useState({});

// Handlers for updating state 
const updateCourseInfo = e => {
    setCourseInfo({
        ...courseInfo,
        [e.currentTarget.name]: e.currentTarget.value,
    })
}


// Hasura mutaiton defined
const ADD_COURSE = gql`
mutation AddCourse($course_name: String, $description: String, $instructor: String) {
    insert_courses_one(object: {course_name: $course_name, description: $description, instructor: $instructor}) {
    course_name
    description
    instructor
    id
  }
}
`;
const [addCourse] = useMutation(ADD_COURSE)

const submitCourse = () => {
    addCourse({ variables: {
        course_name: courseInfo.courseTitle,
        description: courseInfo.courseDescription,
        instructor: user.name
    }})
    navigate('/account/dashboard');
}


    return (
        <div>
            <h2>Create a new course below, {user.name.split(' ')[0]}</h2>
            <input type="text" name="courseTitle" autoComplete="off" value={courseInfo.courseTitle} onChange={updateCourseInfo} />
            <input type="text" name="courseDescription" autoComplete="off" value={courseInfo.courseDescription} onChange={updateCourseInfo} />
            <a onClick={submitCourse}>Create Course</a>
        </div>
    )
}

