import React, { useState, useEffect, useMemo } from 'react'
import { navigate, Link } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import styled from "styled-components"
import Confetti from 'react-dom-confetti';
import { motion } from "framer-motion";
import { Breadcrumb, Current } from "../../styles/lib"



export default function Quiz(props) {
    const { quiz, course, user} = props.location.state;

     // state to control question being displayed
     const [count, setCount] = useState(1);
     const [studentAnswer, setStudentAnswer] = useState('');
     const [isCorrect, setIsCorrect] = useState('');
     const [xp, setXP] = useState(null);
     const [attempts, setAttempts] = useState(0);
     const [helpCount, setHelpCount] = useState(0);
     const [celebration, setCelebration] = useState(false);

    // Grab user's XP from hasura
    const APOLLO_QUERY = gql`
    query MyQuery($parent_course: String, $student: String) {
      enrollments(where: {course_id: {_eq: $parent_course}, student_name: {_eq: $student}}, ) {
        xp
      }
    }
    `;

  const { loading, error, data, refetch } = useQuery(APOLLO_QUERY, {variables: {
    parent_course: course.id,
    student: user.name
  },
  },{ fetchPolicy: "network-only" });

  useEffect(() => {
    if(data) {
      setXP(data.enrollments[0].xp + quiz.xp)
      console.log(`The potential new value is ${xp}`);
    }
  }, [xp, count])


    // Shuffle questions
    const shuffleQuestions = useMemo(() => {
      quiz.questions = quiz.questions.sort(() => Math.random() - 0.5)
    }, [])

    const updateValue = e => {
        setStudentAnswer(e.currentTarget.value)
      }
    
    const correctAnswer = () => {
        // Set the helpCount back to zero
        setHelpCount(0);
        // Up the count by one
        setAttempts(attempts + 1)
        // Update the state's value
        setStudentAnswer('');
    }

    // Hasura mutaiton defined
    const ADD_COMPLETION = gql`
        mutation AddCompletion($course_title: String, $quiz_id: String, $quiz_title: String, $student: String, $xp: Int, $score: Int) {
        insert_completions_one(object: {course_title: $course_title, quiz_id: $quiz_id, quiz_title: $quiz_title, student: $student, xp: $xp, score: $score}) {
        id
    }
    }
    `;
    const UPDATE_XP = gql`
    mutation UpdateXP($course_id: String, $student_name: String, $xp: Int) {
  update_enrollments(where: {course_id: {_eq: $course_id}, student_name: {_eq: $student_name}}, _set: {xp: $xp}) {
    returning {
      student_name
      xp
    }
  }
}
`;

    const [addCompletion] = useMutation(ADD_COMPLETION)
    const [updateXP] = useMutation(UPDATE_XP)

    const endQuiz = () => {
        setAttempts(attempts + 1)
        addCompletion({ variables: {
            course_title: course.course_name,
            quiz_id: quiz.quiz_id,
            quiz_title: quiz.quiz_title,
            student: user.name,
            xp: quiz.xp,
            score: Math.round(((count - 1) / attempts) * 100)
        }})
        updateXP({ variables: {
            course_id: course.id,
            student_name: user.name,
            xp: xp
        }})
        navigate('/account/course', { state: {course} });
    }

      const checkAnswer = () => {
        // Up the attempts by one
        setAttempts(attempts + 1);
        if(studentAnswer.toUpperCase() == quiz.questions[count - 1].correct_answer.toUpperCase() && count >= quiz.questions.length) {
                setCelebration(true);
                setCount(1);
                setTimeout(function() {
                  endQuiz();
                }, 2000);
            } else if (studentAnswer.toUpperCase() == quiz.questions[count - 1].correct_answer.toUpperCase() && count) {
              correctAnswer()
              setTimeout(function() {
                setCount(count + 1)
              }, 100);
            } else if  (studentAnswer.toUpperCase() != quiz.questions[count - 1].correct_answer.toUpperCase() && count && helpCount < 3) {
                setHelpCount(helpCount + 1);
            }
      }

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          checkAnswer();
        }
      }

      // For confetti
      const config = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: "161",
        dragFriction: 0.12,
        duration: "4340",
        stagger: 3,
        width: "24px",
        height: "25px",
        perspective: "764px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
      };
      
    return (
        <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Confetti active={celebration} config={ config }/>
          <Breadcrumb style={{ placeSelf: `start` }}>
            <li><Link to="/account">My Account</Link></li>
            <li><Link to="/account/dashboard">My Dashboard</Link></li>
            <li><Link to="/account/course" state={{ course: course, user: user }} >{ course.course_name }</Link></li>
            <li><Current>{ quiz.quiz_title }</Current></li> 
            </Breadcrumb>
            {quiz.img_path != null ? <QuizImg src={quiz.img_path} alt="quiz image"/> : ''}
            <QuizContent>
              {/* Progress bar */}
              <Bar>
                <div progress={`${(quiz.questions.indexOf(quiz.questions[count-1]) / quiz.questions.length) * 100}%`}>
                </div>
              </Bar>
              <Details>
                {/* Average on left and answer area on right */}
                <QuizStats>
                  <p style={count <= 1 ? {opacity: 0} : {opacity: 1}} >Average: {Math.round(((count - 1) / attempts) * 100)}%</p>
                </QuizStats>
                <AnswerBlock style={ celebration ? {opacity: 0} : {opacity: 1} }>
                  <h3>{quiz.questions.indexOf(quiz.questions[count-1]) + 1}: {quiz.questions[count - 1].question}</h3>
                  <label htmlFor="studentAnswer">ANSWER:</label>
                  <input type="text" name="studentAnswer" autoComplete="off" value={studentAnswer} onChange={updateValue} onKeyDown={handleKeyDown}/>
                  <Cheatsheet style={helpCount == 3 ? {opacity: 1} : {opacity: 0}}>
                    <p>Looks like you're having some trouble! Here's the answer: <strong>{quiz.questions[count - 1].correct_answer}</strong></p>
                  </Cheatsheet>
                  <a onClick={checkAnswer}>Submit</a>
                </AnswerBlock>
              </Details>
            </QuizContent>
        </PageWrapper>
    )
}


const PageWrapper = styled(motion.div)`
  display: grid;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  place-items: center center;
  font-family: Gotham Black;

  h2 {
    font-weight: 800;
    font-size: 36px;
  }
`

const Cheatsheet = styled.div`
  transition: .1s;
  font-family: Gotham thin;
  padding: 10px 15px;
  margin-top: 5px;
  border-left: solid 4px rgba(248, 109, 112, 1);
  background: rgba(248, 109, 112, .4);

  strong {
    font-family: Gotham black;
    font-weight: 800;
  }
`

const QuizContent = styled.div`
  display: grid;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 2vh;
`

const QuizImg = styled.img`
  /* max-width: 100%;
  height: auto; */
  height: 60vh;
  max-width: 100%;
  place-self: center;
  border: solid 1px black;
  border-radius: 13px;
`
const Bar = styled.div`
  height: 20px;
  width: 100%;
  border-radius: 13px;
  border: solid 1px black;

div {
  height: 100%;
    width: ${props => props.children.props.progress};
    background-color: black;
    border-radius: inherit;
    text-align: right;
    transition: width ease-in-out .3s;
 

  span {
    padding: 5;
    color: white;
  }
}
`

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`

const QuizStats = styled.div`
  p {
    transition: ease-in-out .3s;
  }
`

const AnswerBlock = styled.div`
  display: grid;
  font-family: Gotham Black;
  transition: ease-in-out .3s;

  a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 5px 10px;
        border-radius: 13px;
        place-self: start start;
        margin-top: 10px;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
            cursor: pointer;
        }
    }

    input {
    border-radius: 13px;
    font-family: Gotham Black;
    outline: none;
  }
`