import React, { useState } from 'react'
import { Link } from "gatsby"
import { useQuery, useMutation  } from '@apollo/client';
import gql from 'graphql-tag';
import styled from "styled-components"
import { motion } from "framer-motion"
import { PageWrapper } from "../../../styles/lib"

export default function EditQuiz(props) {
    const { course, quiz } = props.location.state;
    const { user } = props;

    // Load the questions from the db
    const APOLLO_QUERY = gql`
    query MyQuery($quiz_id: String) {
    quizzes(where: {quiz_id: {_eq: $quiz_id}}) {
        quiz_title
        quiz_id
        quiz_description
        img_path
        questions(order_by: {order: asc}) {
        id
        question
        correct_answer
        }
    }
    }
    `;

    const { loading, error, data, refetch } = useQuery(APOLLO_QUERY, {variables: {
        quiz_id: quiz.quiz_id
    }});

    // For new questions
    const [newQuestionInfo, setNewQuestionInfo] = useState({})

    // For adding question/answer
    const handleNew = e => {
        setNewQuestionInfo({
            ...newQuestionInfo,
            [e.currentTarget.name]: e.currentTarget.value,
        })}
    
    // Hasura mutaiton defined
    const ADD_QUESTION = gql`
    mutation AddQuestion($question: String, $parent_quiz: String, $correct_answer: String) {
    insert_questions_one(object: {question: $question, parent_quiz: $parent_quiz, correct_answer: $correct_answer}) {
    id
    }
    }
    `;
    const [addQuestion] = useMutation(ADD_QUESTION)

    // TODO: This refetch doesn't work very well...
    const submitNewQuestion = () => {
        addQuestion({ variables: {
            question: newQuestionInfo.newQuestion,
            correct_answer: newQuestionInfo.newAnswer,
            parent_quiz: quiz.quiz_id
        },
        refetchQueries: [{
            query: APOLLO_QUERY,
            variables: {
                quiz_id: quiz.quiz_id
            },
          }],
    })
    }

    // Edit question
    const [editedQuestionInfo, setEditedQuestionInfo] = useState({})

    // For adding question/answer
    const handleEdit = e => {
        setEditedQuestionInfo({
            ...editedQuestionInfo,
            [e.currentTarget.name]: e.currentTarget.value,
        })}
    
    // Hasura mutaiton defined
    const EDIT_QUESTION = gql`
    mutation EditQuestion($question: String, $id: String, $correct_answer: String) {
        update_questions(_set: {question: $question, correct_answer: $correct_answer}, where: {id: {_eq: $id}}) {
    affected_rows
  }
    }
    `;
    const [editQuestion] = useMutation(EDIT_QUESTION)

    const submitEditedQuestion = (question_id) => {
        editQuestion({ variables: {
            question: editedQuestionInfo.editedQuestion,
            id: question_id, 
            correct_answer: editedQuestionInfo.editedAnswer
        },
        options: {
            awaitRefetchQueries: true
       }
    }, 
    {
        refetchQueries: [
          { query: APOLLO_QUERY }
        ]
      })
    }

    return (
        <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <h2>{quiz.quiz_title}</h2>
            <Content>
            <div>
            {loading && <p>Loading questions...</p>}
            {data && data.quizzes[0].questions.map((question, i) =>
            <Question key={question.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <h5>{i + 1}.</h5>
                <label htmlFor="editedQuestion">Q:</label>
                <Input type="text" name="editedQuestion" autoComplete="off" placeholder={question.question} onChange={handleEdit}/>
                <label htmlFor="editedAnswer">A:</label>
                <Input type="text" name="editedAnswer" autoComplete="off" placeholder={question.correct_answer} onChange={handleEdit}/>
                <ButtonPrimary onClick={() => submitEditedQuestion(question.id)}>Update question</ButtonPrimary>
            </Question>
            )}
            <h4>Add question:</h4>
            <Input type="text" name="newQuestion" autoComplete="off" value={newQuestionInfo.newQuestion} onChange={handleNew}/>
            <Input type="text" name="newAnswer" autoComplete="off" value={newQuestionInfo.newAnswer} onChange={handleNew}/>
            <ButtonPrimary onClick={submitNewQuestion} >Add question</ButtonPrimary>
           </div>
           {data && data.quizzes[0].img_path != null ? <QuizImg src={data.quizzes[0].img_path} alt="quiz image"/> : ''}
           </Content>
        </PageWrapper>
    )
}

const Question = styled(motion.div)`
    font-family: Gotham black;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    label {
        margin: 5px 10px 5px 0;
    }

    h5 {
        font-size: 18px;
        font-weight: 800;
        margin: 0;
        margin-right: 5px;
    }
`

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
`

const QuizImg = styled.img`
  width: 20vw;
  height: auto;
  border: solid 1px black;
  border-radius: 13px;
  padding-left: 10vh;
  padding-right: 10vh;
  place-self: start center;
`

const Input = styled.input`
    font-family: Gotham thin;
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 5px 15px;
    border-radius: 13px;
    font-size: 16px;
    outline: none;
`

const ButtonPrimary = styled.a`
    font-family: Gotham black;
    text-decoration: none;
    color: black;
    border: solid 1px black;
    padding: 5px 15px;
    border-radius: 13px;
    place-self: start start;
    transition: ease-in-out .3s;

    &:hover {
        color: white;
        background: black;
        cursor: pointer;
    }
`
