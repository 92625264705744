import React, { useState, useEffect } from 'react'
import { Link } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { PageWrapper, Breadcrumb, Current, DashChallengeContainer } from "../../../styles/lib"
import ScoreCard from "../course/scoreCard"
import Challenges from "../course/challenges"



export default function StudentDetail(props) {
    const { course } = props.location.state;
    const { user } = props;
    const { student } = props.location.state;


    // Load the student's data
// Set state
const [stats, setStats] = useState(null);
const [quizzes, setQuizzes] = useState({});
const [quizArray, setQuizArray] = useState(null);

// get the completions by this use for this course
const STATS_QUERY = gql`
query MyQuery($student: String, $course_id: String) {
completions(where: {student: {_eq: $student}, course_title: {_eq: $course_id}}, order_by: {quiz_title: asc}) {
    date
    quiz_title
    score
}
}

`;

// TODO: Some logic to pass student name as state instead of JUST user's name
const { loading, error, data } = useQuery(STATS_QUERY, {variables: {
course_id: course.course_name,
student: student.student_name
}});


// compute a course average and a by-quiz set of stats
useEffect(() => {
    if(data){
        // Set the state
        setStats(data); // should be able to delete this
        data.completions.map((c) => (
            quizzes.hasOwnProperty(c.quiz_title) ? setQuizzes(quizzes[c.quiz_title].iterations.push(c)) : setQuizzes(prevState => {
                return {
                ...prevState,
                [c.quiz_title] : {
                    iterations: []
                }
             }})
        ))
        const transformedQuiz = Object.entries(quizzes).map((e) => ( { title: e[0], iterations: e[1] } ));
        setQuizArray(transformedQuiz);
    }
    console.log(`ran`)
}, [data, stats])


return (
    <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Breadcrumb>
        <li><Link to="/account">My Account</Link></li>
        <li><Link to="/account/dashboard">My Dashboard</Link></li>
        <li><Link to="/account/course" state={{ course: course, user: user }}>{course.course_name}</Link></li>
        <li><Current>Stats for {student.student_name}</Current></li> 
        </Breadcrumb>
        <DashChallengeContainer>
            <Challenges  course={course}  user={{name: student.student_name}} />
        </DashChallengeContainer>
        {quizArray && quizArray.map((quiz) => 
            <ScoreCard quiz={quiz} iterations={quiz.iterations} />
        )}
    </PageWrapper>
)
}
