import React, { useState } from 'react'
import { Link } from "gatsby"
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import styled from "styled-components";
import CourseCard from "./courseCard"
import { PageWrapper, CourseGallery, Breadcrumb, Current } from "../../../styles/lib"

export default function Dashboard({ user }) {

const APOLLO_QUERY = gql`
query MyQuery($student_name: String) {
  enrollments(where: {student_name: {_eq: $student_name}}, order_by: {course: {course_name: asc}}) {
    course {
      id
      course_name
      description
      instructor
    }
  }
}
`;

const [courseInfo, setCourseInfo] = useState({})

    // For adding question/answer
    const handleChange = e => {
        setCourseInfo({
            ...courseInfo,
            [e.currentTarget.name]: e.currentTarget.value,
        })}

// New class enrollment
const ADD_ENROLLMENT = gql`
mutation AddEnrollment($course_id: String, $student_name: String) {
  insert_enrollments_one(object: {course_id: $course_id, student_name: $student_name, xp: 0}) {
    student_name
    course_id
    xp
  }
}
`;
const [addEnrollment] = useMutation(ADD_ENROLLMENT)

const submitEnrollment = () => {
    addEnrollment({ variables: {
        course_id: courseInfo.id,
        student_name: user.name,
        xp: 0
    },
    options: {
      awaitRefetchQueries: true
 }
})
    refetch();
}

console.log(user);

const { loading, error, data, refetch } = useQuery(APOLLO_QUERY, { variables: {student_name: user.name} });

    return (
        <PageWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Breadcrumb>
            <li><Link to="/account">My Account</Link></li>
            <li><Current>My Dashboard</Current></li> 
            </Breadcrumb>
            <h2>Here are your courses, {user.name.split(' ')[0]}:</h2>
            {/* {loading && <li>Loading classes...</li>} */}
            {error && <p>Error: ${error.message}</p>}
            <CourseGallery>
            {data && data.enrollments.map((enrollment) =>  
            <CourseCard course={enrollment.course } />
            )}
            <h3>Sign up for a course</h3>
            <p>Enter the registration code below:</p>
            <input type="text" name="id" autoComplete="off" value={courseInfo.id} onChange={handleChange}/>
            <a onClick={submitEnrollment}>Sign me up!</a>
            {/* <h5>IFF Teacher: <Link to="/account/createCourse" state={{ user: user }}>Create new course</Link></h5> */}
            </CourseGallery>
        </PageWrapper>
    )
}

