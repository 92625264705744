import React, { useState, useEffect, useMemo } from 'react'
import styled from "styled-components"
import {Link} from "gatsby"



export default function CourseCard({ course }) {
  return (
    <Card>
        <div>
        <h3>{ course.course_name }</h3>
        <p>{ course.description }</p>
        <Link to="/account/course" state={{ course: course }} >Play some games!</Link>
        </div>
    </Card>
  )
}

const Card = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    min-width: 100%;
    margin-bottom: 5vh;
    

    h3 {
        font-size: 28px
    }

    p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5vh;
    }

    a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        place-self: start start;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
        }
    }

    @media(max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
