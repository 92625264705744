import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client';
import Moment from 'react-moment';
import gql from 'graphql-tag';
import useDeepCompareEffect from 'use-deep-compare-effect'



export default function Challenges({ user, course, view }) {

    const [challenges, setChallenges] = useState([]);
    const [challengesLoaded, setChallengesLoaded] = useState(`unloaded`);

    //  Query for ACTUAL data
    const STATS_QUERY = gql`
    query MyQuery($student: String, $course_id: String, $course_number: String) {
    completions(where: {student: {_eq: $student}, course_title: {_eq: $course_id}}) {
        date
        quiz_title
        score
        xp
    }
    challenges(where: {parent_course: {_eq: $course_number}}) {
        name
        goal
        eligible_quizzes
        start
        end
  }
    }`;
    
    const { loading, error, data } = useQuery(STATS_QUERY, {variables: {
        parent_course: course.id,
        student: user.name,
        course_number: course.id
      }
      });

      useEffect(() => {
        if(data) {
        // Populate the state object
        data.challenges.map((challenge) => (
            challenges.some(e => e.challenge.name == challenge.name) ? null : setChallenges(prevState => [...prevState, {
                challengeName : challenge.name,
                challenge: challenge,
                progress: 0
            }]
            )
        ));
        }
      }, [data])

      useDeepCompareEffect(() => {
          if(data) {      
          setChallengesLoaded('loading');
          // Loop over the completions and sum values
          challenges.map((challenge, i) => {
              let start = new Date(challenge.challenge.start);
              start.setHours(0, 0, 0);
              let end = new Date(challenge.challenge.end);
              end.setHours(23, 59, 0);
              data.completions.map(completion => {
                  if(challenge.challenge.eligible_quizzes.split(', ').includes(completion.quiz_title)) {
                      let compDate = new Date(completion.date);
                      console.log(`Start: ${start}\nEnd: ${end}\nIteration: ${compDate}`)
                      if(compDate >= start && compDate <= end) {
                          let newChallenges = challenges;
                          newChallenges[i] = {...newChallenges[i], progress: newChallenges[i].progress += completion.xp};
                          setChallenges(newChallenges)
                      }
                  }
              })
          })
        }
        console.log(`useEffect ran`)
      }, [challenges])

    if(view == `table`) {
        return (
            <>
            {challenges.map((challenge) => (
                <tr key={challenge.challengeName} style={ challenge.progress >= challenge.challenge.goal ? { background: `#41b154`} : {background: `rgba(248, 109, 112, .6)`}}>
                        <td>{user.name}</td>
                        <td><Bar>
                            <div progress={challenge.progress > challenge.challenge.goal ? `100%` : `${(challenge.progress / challenge.challenge.goal) * 100}%`}>
                            </div>
                        </Bar></td>
                        <td>{challenge.progress} / {challenge.challenge.goal}</td>
                </tr>
            ))}
        </>
        )
    }
    return (
        <div>
            {challenges.map((challenge) => (
                <Challenge key={challenge.challengeName}>
                    <div>
                        <h2>{challenge.challengeName}</h2>
                        <p>These quizzes are part of this challenge:</p>
                        <ul>
                            {challenge.challenge.eligible_quizzes.split(', ').map((quiz) => (
                                <li key={quiz}>{quiz}</li>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <Bar>
                            <div progress={challenge.progress > challenge.challenge.goal ? `100%` : `${(challenge.progress / challenge.challenge.goal) * 100}%`}>
                            </div>
                        </Bar>
                        <ChallengeDeets>
                            <p>{challenge.progress} XP out of {challenge.challenge.goal} earned</p> 
                            <p>Available until <Moment format="dddd MMMM DD">{challenge.challenge.end}</Moment></p>
                        </ChallengeDeets>
                    </div>
                </Challenge>
            ))}
        </div>
    )
}


export const Challenge = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    padding: 40px;
    margin-bottom: 10vh;
    border-radius: 13px;
    box-shadow: 0 6px 8px rgba(0,0,0,.4);
    
    h2 {
        font-family: Gotham black;
        font-weight: 400;
        font-size: 24;
        text-transform: uppercase; 
        margin-top: 0;
    }

    p {
        margin-bottom: 4vh;
        font-size: 22px;
        /* line-height: 1.3rem; */
    }

    a {
        text-decoration: none;
        color: black;
        border: solid 1px black;
        padding: 15px 40px;
        border-radius: 13px;
        place-self: start start;
        transition: ease-in-out .3s;

        &:hover {
            color: white;
            background: black;
            cursor: pointer;
        }
    }
`

const Bar = styled.div`
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 13px;
  border: solid 1px black;

div {
  height: 100%;
    width: ${props => props.children.props.progress};
    background-color: black;
    border-radius: inherit;
    text-align: right;
    transition: width ease-in-out .3s;
 

  span {
    padding: 5;
    color: white;
  }
}
`

export const ChallengeDeets = styled.div`
    display: block;
    
    p {
        font-size: 12px;
        margin: 0;
    }
`