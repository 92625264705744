import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import ImgUpload from "./imgUpload"

export default function CreateQuiz(props) {
    const { course } = props.location.state;
    const { user } = props;

// State for form
const [quizInfo, setQuizInfo] = useState({});

// Handlers for updating state (I know I can do this in one instead of n...)
const updateQuizInfo = e => {
    setQuizInfo({
        ...quizInfo,
        [e.currentTarget.name]: e.currentTarget.value,
    })
}


// Hasura mutaiton defined
// TODO: Need to add img field for upload
const ADD_QUIZ = gql`
mutation AddQuiz($quiz_title: String, $parent_course: String, $quiz_description: String) {
insert_quizzes_one(object: {quiz_title: $quiz_title, parent_course: $parent_course, quiz_description: $quiz_description}) {
quiz_id
}
}
`;
const [addQuiz] = useMutation(ADD_QUIZ)

const submitQuiz = () => {
    addQuiz({ variables: {
        quiz_title: quizInfo.quizTitle,
        quiz_description: quizInfo.quizDescription,
        parent_course: course.id
    }})
    navigate('/account/course', { state: {course} });
}


    return (
        <div>
            <h2>Create a quiz for {course.course_name}</h2>
            <input type="text" name="quizTitle" autoComplete="off" value={quizInfo.title} onChange={updateQuizInfo} />
            <input type="text" name="quizDescription" autoComplete="off" value={quizInfo.description} onChange={updateQuizInfo} />
            {/* <ImgUpload /> */}
            <a onClick={submitQuiz}>Create Quiz</a>
        </div>
    )
}

